@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #f3f4f6;
}

.icon-background-red {
  background-color: #ee524f; /* Rote Hintergrundfarbe */
  padding: 0.5rem;
  border-radius: 5%; /* Optional: Runde Ecken für das Icon */
}

/* index.css oder App.css */
.item-tile {
  background-color: #ee524f;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 0.5rem;
  text-align: center;
  width: 6rem; /* Breite kann nach Bedarf angepasst werden */
  height: 10rem; /* Höhe wurde erhöht */
  margin: 0.5rem;
  position: relative; /* Für das Hover-Icon */
}

.item-tile img {
  max-width: 100%;
  max-height: 60%; /* Höhe für das Bild wurde angepasst */
  object-fit: contain;
  margin-bottom: 0.5rem;
}

.item-tile .item-name {
  font-size: 1rem;
  font-weight: bold;
}

.item-tile .item-details {
  font-size: 0.875rem;
}

.item-tile button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
}
